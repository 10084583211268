import { default as indexNbSlbJvFC0Meta } from "/home/runner/work/dek-space/dek-space/src/pages/index.vue?macro=true";
export default [
  {
    name: indexNbSlbJvFC0Meta?.name ?? "index",
    path: indexNbSlbJvFC0Meta?.path ?? "/",
    meta: indexNbSlbJvFC0Meta || {},
    alias: indexNbSlbJvFC0Meta?.alias || [],
    redirect: indexNbSlbJvFC0Meta?.redirect || undefined,
    component: () => import("/home/runner/work/dek-space/dek-space/src/pages/index.vue").then(m => m.default || m)
  }
]