import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";




export default defineNuxtPlugin(nuxtApp => {
	nuxtApp.vueApp.use(Toast, {
		transition: "Vue-Toastification__bounce",
		maxToasts: 20,
		newestOnTop: true,
		position: "top-center",
		timeout: 4000,
		closeOnClick: true,
		pauseOnFocusLoss: true,
		pauseOnHover: true,
		draggable: true,
		draggablePercent: 0.6,
		showCloseButtonOnHover: false,
		hideProgressBar: true,
		closeButton: "button",
		icon: true,
		rtl: false
	});

	// nuxtApp.provide('injected', () => 'my injected function')
	// now available on `nuxtApp.$injected`
})
